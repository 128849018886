import Icon from "../../../../../../assets/svg/ceritakan_masalahmu_icon.svg";

export const CeritakanMasalahmu = () => {
  return (
    <div className="h-full w-full flex items-center justify-center bg-ceritakan-masalahmu bg-no-repeat bg-cover rounded-[12px] relative">
      <div className="flex items-center justify-between px-4 md:px-16 h-full w-[95%]">
        <div>
          <h1 className="font-bold text-cream text-[16px] sm:text-[24px] sm:text-[32px] md:text-[40px] xl:text-[56px] ">
            Ceritakan
          </h1>
          <h1 className="font-bold text-white text-[16px] sm:text-[24px] sm:text-[32px] md:text-[40px] xl:text-[56px] ">
            Masalahmu
          </h1>
          <p className="my-5 text-[12px] md:text-[18px]">
            Dan cari jalan keluarnya bersama Kami
          </p>
          <button
            className="bg-white font-bold px-[16px] py-[8px] rounded-[26px]"
            onClick={() => window.open("https://wa.me/6282211624891")}
          >
            <div className="text-primary text-caption">Jadwalkan Sesi</div>
          </button>
        </div>
        <img
          src={Icon}
          alt="icon"
          className="w-[40vw] absolute bottom-0 md:-bottom-4 right-0"
        />
      </div>
    </div>
  );
};
