import { Header } from "../header";
import { Footer } from "../footer";
import { Widget } from "../widget";

interface Props {
  children: any;
}

export const Template = ({ children }: Props) => {
  return (
    <div className="w-full max-w-screen bg-[#F9F9F9]">

      <div className="py-8">
        <Header/>
      </div>

      <div className="content max-w-screen">
        {children}
        <Footer/>
        <Widget location="fixed bottom-4 right-4"/>
      </div>
    </div>
  );
};
