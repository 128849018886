import { Images } from "../../assets/png";
import {
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebookF,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="flex flex-col md:flex-row px-[7%] max-w-screen gap-[50px] md:gap-[100px] lg:gap-[350px] bg-white justify-evenly py-16">
      <div className="flex flex-col gap-[20px] md:gap-[70px]">
        <div>
          <img src={Images.nav_logo} width={140} alt="mudita logo" />
          <p className="text-caption">PT Cita Karsa Mudita</p>
        </div>
        <ul className="flex gap-[12px]">
          <li>
            <button
              onClick={() =>
                window.open("https://www.linkedin.com/company/with-mudita")
              }
            >
              <FaLinkedin size={30} color="#00A2AD" />
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                window.open("https://www.instagram.com/withmudita ")
              }
            >
              <FaInstagram size={30} color="#00A2AD" />
            </button>
          </li>
          <li>
            <FaTwitter size={30} color="#00A2AD" />
          </li>
          <li>
            <FaYoutube size={30} color="#00A2AD" />
          </li>
          <li>
            <FaFacebookF size={30} color="#00A2AD" />
          </li>
        </ul>
      </div>
      <div className="flex flex-col sm:flex-row gap-[50px] sm:gap-[100px]">
        <div>
          <h1 className="text-body-bold">Tentang Kami</h1>
          <img
            src={Images.separator}
            alt="separator"
            width={30}
            className="my-2"
          />
          <ul className="text-caption flex flex-col gap-[8px]">
            <li>Tentang Perusahaan</li>
            <li>Syarat dan Ketentuan</li>
            <li>Kebijakan Privasi</li>
            <li>
              <button
                onClick={() => window.open("https://wa.me/6282211624891")}
              >
                Event dan Partnership
              </button>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-body-bold mb-2">Layanan Kami</h1>
          <img
            src={Images.separator}
            alt="separator"
            width={30}
            className="my-2"
          />
          <ul className="text-caption flex flex-col gap-[8px]">
            <li
              onClick={() => {
                navigate("/services", { state: { service: "service_1" } });
              }}
              className="cursor-pointer"
            >
              Company Check Up
            </li>
            <li
              onClick={() => {
                navigate("/services", { state: { service: "service_2" } });
              }}
              className="cursor-pointer"
            >
              Seminar Interaktif
            </li>
            <li
              onClick={() => {
                navigate("/services", { state: { service: "service_3" } });
              }}
              className="cursor-pointer"
            >
              Konseling Kelompok
            </li>
            <li
              onClick={() => {
                navigate("/services", { state: { service: "service_4" } });
              }}
              className="cursor-pointer"
            >
              Psikolog Kantor/Kampus
            </li>
            <li
              onClick={() => {
                navigate("/services", { state: { service: "service_5" } });
              }}
              className="cursor-pointer"
            >
              One on One Coaching
            </li>
            <li>Kuota Konseling Daring</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
