import { useState, useCallback, SetStateAction, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { CeritakanMasalahmu } from "./slides/ceritakan_masalahmu";
import { JagaProduktivitas } from "./slides/jaga_produktivitas";
import { LangkahKonseling } from "./slides/langkah_konseling";
import Autoplay, { AutoplayOptionsType } from "embla-carousel-autoplay";
import MobileKiri from "../../../../assets/png/mobile_kiri.png";
import MobileTengah from "../../../../assets/png/mobile_tengah.png";
import MobileKanan from "../../../../assets/png/mobile_kanan.png";

const carouselData = [
  {
    id: 1,
    Component: CeritakanMasalahmu,
    image: MobileKiri,
  },
  {
    id: 2,
    Component: LangkahKonseling,
    image: MobileTengah,
  },
  {
    id: 3,
    Component: JagaProduktivitas,
    image: MobileKanan,
  },
];

type CarouselPillProps = {
  selected: boolean;
  key: number;
  onClick: () => void;
};

const autoplayOption: AutoplayOptionsType = {
  delay: 7000,
  stopOnInteraction: false,
};

const CarouselPill = (props: CarouselPillProps) => {
  const { selected, key, onClick } = props;

  return (
    <button
      key={key}
      className={`${
        selected
          ? "bg-primary h-3.5 w-8 rounded-full"
          : "bg-secondary rounded-full h-3.5 w-3.5"
      } transition-all duration-[650ms]`}
      onClick={onClick}
    ></button>
  );
};

export const Slider = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay(autoplayOption),
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onInit = useCallback(
    (emblaApiParams: { scrollSnapList: () => SetStateAction<number[]> }) => {
      setScrollSnaps(emblaApiParams.scrollSnapList());
    },
    []
  );

  const onSelect = useCallback(
    (emblaApiParams: { selectedScrollSnap: () => SetStateAction<number> }) => {
      setSelectedIndex(emblaApiParams.selectedScrollSnap());
    },
    []
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <div className="embla h-[24em] sm:h-full rounded-[12px]" id="slider">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="hidden sm:inline embla__container">
            {carouselData.map((carousel) => {
              const { id, Component } = carousel;
              return (
                <div className="embla__slide sm:mx-12" key={id}>
                  <div className="hidden sm:inline w-full sm:h-full h-1/2">
                    <Component />
                  </div>
                  <img
                    src={carousel.image as string}
                    alt="mobile sm:hidden"
                    onClick={() => window.open("https://wa.me/6282211624891")}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="embla__pills" id="konseling">
        {scrollSnaps.map((_, index) => (
          <CarouselPill
            key={index}
            onClick={() => scrollTo(index)}
            selected={index === selectedIndex}
          />
        ))}
      </div>
    </>
  );
};
