import {Seperator} from "../../../../components/seperator";
import ScrollContainer from "react-indiana-drag-scroll";
import {MouseEventHandler, TouchEventHandler, useEffect, useRef, useState} from "react";

type CardProps = {
  data: DataProps;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: Function
  onTouchStart?: TouchEventHandler<HTMLDivElement>;
};
type DataProps = {
  img: string;
  name: string;
  company_name: string;
  review: string;
};

const reviews = [
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+1.png",
    name: "Cantika",
    company_name: "Fresh Graduate",
    review:
      "Sangat puas denagn materi, cara pemaparan, serta training yang diberikan. Sangat membantu mempercepat pemahaman.",
  },
  {
    img: "/static/1.png",
    name: "Nadya",
    company_name: "Karyawan Swasta",
    review:
      "Seru banget, cuma 2 kali meet, tapi berasa padat. Di dalemnya juga ada latihan, nggak cuma teoretis, tapi langsung dipraktikkan.",
  },
  {
    img: "/static/2.png",
    name: "Hari",
    company_name: "HR Staff",
    review:
      "Acaranya sangat well-prepared. Narasumber juga sangat menguasai materi sehingga semua pertanyaan terjawab dengan baik.",
  },
  {
    img: "/static/3.png",
    name: "Bila",
    company_name: "People Management",
    review:
      "Lebih memiliki pengetahuan dan kompetensi dalam hal praktik konseling sehingga dapat diterapkan untuk sekitar",
  },
  {
    img: "/static/4.png",
    name: "Anya",
    company_name: "Ners",
    review:
      "Bisa lebih memahami curhatan orang, nggak langsung ingin kasih solusi. Lebih sabar dan nggak buru-buru saat dengern dan less judgemental.",
  },
  {
    img: "/static/5.png",
    name: "Daniel",
    company_name: "Mahasiswa",
    review:
      "Menjadi lebih bisa menghargai perasaan orang lain saat mendengar cerita dan melakukan active listening secara efektif.",
  },
];

const Card = ({data, onMouseEnter, onMouseLeave, onTouchStart}: CardProps) => {
  return (
    <div
      className="p-6 rounded-md bg-white mb-4 shadow-sm flex-shrink-0 w-[300px] max-w-[90vw]"
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onMouseLeave={() => onMouseLeave ? onMouseLeave() : {}}
      onBlur={() => onMouseLeave ? onMouseLeave() : {}}
    >
      <div className="flex flex-row mb-3">
        <img
          src={data.img}
          className=" w-8 h-8 rounded-full mr-2"
          alt="reviews"
        />
        <span className="self-center text-caption-bold">
          {data.name}, {data.company_name}
        </span>
      </div>
      <span className="text-caption text-[#505050]">{data.review}</span>
    </div>
  );
};

export const Reviews = () => {
  const [isHovered, setIsHovered] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let scrollInterval: NodeJS.Timer;

    if (!isHovered) {
      // Start slow scrolling to the right when the mouse is not on the element
      scrollInterval = setInterval(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft += 1; // Scroll speed can be adjusted here
          console.log("Scrolling...")
        }
      }, 50); // Adjust interval for slower or faster scrolling
    }

    // Cleanup interval when hovering or unmounting
    return () => clearInterval(scrollInterval);
  }, [isHovered]);

  return (
    <div>
      <div className="container">
        <p className="text-center font-bold text-3xl md:text-4xl text-primary-text whitespace-pre-wrap">
          Apa
          <span className="text-orange"> Kata Mereka </span>
          soal Pelatihan Kami?
        </p>

        <div className="w-full flex items-center justify-center mt-8">
          <Seperator/>
        </div>
      </div>
      <ScrollContainer innerRef={scrollContainerRef} className="mt-8 flex overflow-auto gap-3 px-3 no-scrollbar">
        {reviews.map((review, index) => (
          <Card data={review} key={`i-${index}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onTouchStart={() => setIsHovered(true)}
          />
        ))}
        {reviews.map((review, index) => (
          <Card data={review} key={`j-${index}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onTouchStart={() => setIsHovered(true)}
          />
        ))}
        {reviews.map((review, index) => (
          <Card data={review} key={`k-${index}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onTouchStart={() => setIsHovered(true)}
          />
        ))}
      </ScrollContainer>
    </div>
  );
};
