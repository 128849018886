import { Images } from "../../assets/png";
interface Props {
  location: string;
}

export const Widget = ({ location }: Props) => {
  return (
    <button
      className={`bg-[#333333] text-white rounded-xl flex items-center p-3 sm:p-4 gap-3 z-30 ${location}`}
      onClick={() => window.open("https://wa.me/6282211624891")}
    >
      <img
        src={Images.whatsapp}
        alt="WhatsApp"
        className=" w-1/4 h-1/4 sm:w-fit sm:h-fit"
      />
      <div className="text-left">
        <div className=" flex flex-col sm:flex-row ">
          <h2 className="font-semibold text-xs sm:text-md mr-1">Butuh</h2>
          <h2 className="font-semibold text-xs sm:text-md">Bantuan?</h2>
        </div>
        <p className="text-xs opacity-[80%] mt-1 invisible sm:visible h-0 sm:h-fit ">
          Klik di sini!
        </p>
      </div>
    </button>
  );
};
