import { Images } from "../../../../assets/png";

export const ContactUsCard = () => {
  return (
    <div className="flex justify-center container">
      <div className="flex relative bg-[#00A2AD] justify-center sm:my-16 max-w-[1000px] justify-content items-center md:justify-around min-h-[337px] sm:min-h-[374px] rounded-tl-[24px] md:rounded-tl-[40px] rounded-tr-[64px] rounded-bl-[64px] md:rounded-br-[40px] rounded-br-[24px] flex-col md:flex-row">
        <div className="w-[80%] md:w-[35%]">
          <h1 className="font-semibold text-2xl sm:text-4xl md:text-5xl/normal pb-4 text-white z-40">
            Buat Perubahan Bersama Kami
          </h1>
        </div>
        <div className="flex flex-col w-[80%] md:w-[35%] gap-[20px]">
          <p className="text-white text-[14px] sm:text-[18px] leading-[18px] md:leading-[35px]">
            Buat transformasi psikologis yang yang tak hanya berarti bagi tiap
            individu, tetapi juga bagi pertumbuhan institusi Anda.
          </p>
          <div className="flex items-center justify-center justify-content gap-4">
            <button
              onClick={() => window.open("https://wa.me/6282211624891")}
              className="px-5 rounded-md font-bold text-[#00A2AD] text-sm bg-white sec-button"
            >
              Hubungi Kami
            </button>
            <button className="text-caption text-white">Unduh Proposal</button>
          </div>
        </div>
        <img
          src={Images.curly_left}
          className="absolute top-0 left-0 rounded-tl-[24px] md:rounded-tl-[40px] w-[160px] md:w-[300px]"
          alt="curly"
        />
        <img
          src={Images.curly_right}
          className="absolute bottom-0 right-0 rounded-br-[24px] md:rounded-br-[40px] w-[160px] md:w-[300px]"
          alt="curly"
        />
      </div>
    </div>
  );
};
