import { Seperator } from "../../../../components/seperator";

export const Founder = () => {
  const data = [
    {
      name: "Fariza Nur Shabrina",
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/IMG_9591.jpg",
    },
    {
      name: "Yanhizbar Rotanza",
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/IMG_9627.jpg",
    },
    {
      name: "Irwan Ardiansyah",
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/IMG_9617.jpg",
    },

    {
      name: "Musannidah Kartika",
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/IMG_9645.jpg",
    },
    {
      name: "Shabrina Audinia",
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/IMG_9635.jpg",
    },
  ];

  return (
    <div className="mt-16 container mb-32">
      <p className="mb-8">
        <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] 2xl:text-primary-text whitespace-pre-wrap">
          <span className=" text-orange">Founder </span>
          Mudita
        </span>
      </p>
      <Seperator />
      <div className="flex flex-row mt-8">
        <p className="text-body">
          A small group of thoughtful, committed people can change the world
        </p>
      </div>
      <div className=" grid grid-rows-2 grid-cols-2 md:grid-cols-3 gap-16 mt-16">
        {data.map((item, index) => {
          return (
            <div key={index} className="col-span-1 row-span-1 flex flex-col">
              <img
                src={item.img}
                className=" aspect-square rounded-full self-center w-2/5 object-cover object-top"
                alt="profile"
              />
              <p className=" text-body text-center my-4">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
